$primary: #218721;
$secondary: #051505;
$light: #f2f2f2;
$lightgreen: #f7fdf7;
$grey: #828282;
$blue: #4a6ff0;

@import "node_modules/bootstrap/scss/bootstrap";

// @import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Shadows+Into+Light&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&display=swap");

body {
  font-family: "IBM Plex Mono", monospace;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0.01em;
  min-width: 280px !important;
}

p {
  font-size: 1rem;
  margin-bottom: 0;
}

a,
a:active,
a:link,
a:visited {
  color: #000;
  text-decoration: none !important;
}

p {
  font-size: 1rem !important;
  margin-bottom: 0 !important;
}

// h1 {
//   font-size: 40px !important;
// }

// h2,
// .h2 {
//   font-size: 24px !important;
// }

// h3 {
//   font-size: 20px !important;
// }

// h4,
// .h4 {
//   font-size: 16px !important;
// }

// .subtitle {
//   font-size: 16px;
//   font-weight: 500;
//   letter-spacing: 0.15px;
// }

.body-text1 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.body-text2 {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.body-text3 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.caption {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.row,
.col {
  margin: 0 !important;
}

.text-normal {
  font-weight: normal;
}

.text-bold {
  font-weight: 500 !important;
}

.text-primary {
  color: $primary !important;
}

.text-grey {
  color: $grey !important;
}

.text-blue {
  color: $blue !important;
}

.bg-primary {
  background: $primary !important;
}

.bg-secondary {
  background: $secondary !important;
}

.bg-lightgreen {
  background: $lightgreen !important;
}

.border-primary {
  border: 1px solid $primary !important;
}

.border-secondary {
  border: 1px solid $secondary !important;
}

.border-lightgreen {
  border: 1px solid $lightgreen !important;
}

// -----no focus
.focus-none:focus,
.focus-none:visited {
  // border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: currentColor;
}

// -----input focus
.form-check-input:focus,
.form-select:focus,
.form-control:focus {
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.overline {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.5px;
}

.small-title {
  font-weight: 500;
  font-size: 10.5px;
}

.home-background {
  background-image: url("assets/home/background.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

// focus outline
.outline-none:focus,
.outline-none:active {
  outline: none !important;
  box-shadow: none !important;
}

// focus outline
.outline-text-none:focus,
.outline-text-none:active {
  outline: none !important;
  box-shadow: 4px 4px 10px #111, -5px -5px 10px #555;
}

.wrapper {
  // width: 100%;
  padding: 0 10px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    // max-width: 584px;
    padding: 0 10px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-width: 600px) {
  .wrapper {
    // max-width: 572px;
    padding: 0 20px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    // max-width: 752px;
    padding: 0 20px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-width: 992px) {
  // .content-wrapper {
  //   max-width: 992px;
  // }
  .wrapper {
    // max-width: 964px;
    padding: 0 20px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    // max-width: 1160px;
    padding: 0 20px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1400px) {
  .content-wrapper,
  .wrapper {
    max-width: 1320px;
  }
}

@media (min-width: 576px) {
  .blog-wrapper {
    max-width: 556px !important;
  }
}

@media (min-width: 768px) {
  .blog-wrapper {
    max-width: 682px !important;
  }
}

@media (min-width: 992px) {
  .blog-wrapper {
    max-width: 830px !important;
  }

  .blog-header-wrapper {
    padding: 0 56px !important;
  }
}

@media (min-width: 1200px) {
  .blog-wrapper {
    max-width: 1004px !important;
  }

  .blog-header-wrapper {
    padding: 0 84px !important;
  }
}

@media (min-width: 1400px) {
  .blog-wrapper {
    max-width: 1172px !important;
  }

  .blog-header-wrapper {
    padding: 0 112px !important;
  }
}

.line-limit-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-limit-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-limit-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-limit-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-limit-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-w300 {
  font-weight: 300 !important;
}

.font-w400 {
  font-weight: 400 !important;
}

.font-w500 {
  font-weight: 500 !important;
}

.font-w600 {
  font-weight: 600 !important;
}

//// ----heading 1----
h1 {
  font-size: 64px !important;
  font-weight: 400;
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  h1 {
    font-size: 48px !important;
    font-weight: 400;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 48px !important;
    font-weight: 400;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 32px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

//// ----heading 2----
h2 {
  font-size: 48px !important;
  font-weight: 500;
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  h2 {
    font-size: 40px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: 40px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 24px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

//// ----heading 3----
h3 {
  font-size: 40px !important;
  font-weight: 500;
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  h3 {
    font-size: 32px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  h3 {
    font-size: 32px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  h3 {
    font-size: 20px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

//// ----heading 4----
h4 {
  font-size: 32px !important;
  font-weight: 600;
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  h4 {
    font-size: 24px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  h4 {
    font-size: 24px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  h4 {
    font-size: 18px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 992px) {
  p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 0.94rem !important;
  }
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 0.875rem !important;
    margin-bottom: 0;
  }
}

.subtitle {
  font-size: 24px !important;
  font-weight: 500;
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.bg-whitish {
  background-color: "#F5F5F5" !important;
}

.explore-blogs-btn:hover {
  background-color: $lightgreen !important;
}
